import React from "react";

import forma1 from '../assets/images/formas/forma1.png';
import forma2 from '../assets/images/formas/pintura1.svg';
import { FaArrowDown } from "react-icons/fa6";

export function DesprogramadosBanner() {
    return (
        <>  
            <section id="banner" className="w-screen min-h-screen flex bg-orange-50">
                <div className="mt-60 w-full min-h-screens md:w-full mx-auto text-center relative">
                    
                    <h2 className="z-20 relative w-2/3 text-center mx-auto 
                    md:w-[90%] text-7xl md:text-6xl 4xl:text-8xl">
                        el aula se reinventa,<br/>¿sumas
                        <span 
                            className="bg-no-repeat bg-contain inline-block md:mt-2 animate-fade-up animate-duration-1000 animate-ease-linear"
                            style={{
                                backgroundImage: `url(${forma2})`,
                                backgroundSize: '100%',
                                backgroundPosition: 'center',
                                padding: '.8rem 3rem'
                            }}
                        >
                        <b>tu ficha</b>?
                        </span>
                    </h2>
                   
                    <p className="z-20 relative mx-[1rem] text-xl mt-6 text-center px-10 inline-block text-gray-900 md:mt-2 md:text-lg md:mx-2 md:px-2 mmd:w-[45%] w-[85%]">
                    Con <b>desprogramados</b>, transforma tus <b>capacitaciones</b> en una <b>oportunidad de impacto</b>: fortalece a tus equipos y dona kits para enseñar programación.
                    </p>
                    
                    <br />
                    <a className="mmd:mx-6 mmd:text-3xl text-xl mx-2 mt-12 mx-auto text-center bg-gray-500 border-2 border-gray-500 rounded-full py-2 px-12 inline-block text-white md:mt-10 hover:bg-amber-400" href="#implementacion">
                        EMPRESAS
                    </a>
                    <a className="mmd:mx-6 mmd:text-3xl text-xl mx-2 mt-12 mx-auto text-center border-gray-500 border-2 rounded-full py-2 px-12 inline-block text-gray-500 md:mt-10 hover:bg-amber-400" href="#beneficios">
                        ESCUELAS
                    </a>
                    
                    <img src={forma1} alt="forma" className="w-[85%] z-10 absolute bottom-[-30rem] right-[-50rem] origin-left opacity-[.3] md:right-[-10rem] md:bottom-[-8rem] md:w-[500%] 
                    4xl:w-[75%] lg:top-[-15rem] lg:rotate-[180Deg] lg:right-[-10rem]" />
                </div>
                
               
                
               

            </section>
        </>
    );
}
