import React, { useState } from "react";
import play from "../assets/images/desprogramados/youtube.png";

export default function ModalVideo({
  thumb,
  thumbWidth,
  thumbHeight,
  thumbAlt,
  videoId,
}) {
  const [isPlaying, setIsPlaying] = useState(false);

  return (
    <div className="bg-orange-50 flex flex-col items-center justify-center mmd:mt-[-4rem]">
      {/* Secondary illustration */}
      <div
        className="pointer-events-none absolute bottom-8 left-1/2 -translate-x-1/2 translate-y-1/2 -z-10"
        aria-hidden="true"
      >
        <img
          className="md:max-w-none"
          src={thumb}
          width={1165}
          height={1012}
          alt="Secondary illustration"
        />
      </div>

      {/* Video thumbnail or YouTube iframe */}
      {!isPlaying ? (
        <button
          className="group relative flex items-center justify-center rounded-2xl focus:outline-none focus-visible:ring focus-visible:ring-indigo-200"
          onClick={() => setIsPlaying(true)}
          aria-label="Mira el vídeo"
          data-aos="fade-up"
          data-aos-delay={200}
        >
          <figure className="relative overflow-hidden rounded-2xl before:absolute">
            <img
              className="opacity-80 m-auto border-2 border-gray-500 rounded-2xl"
              src={thumb}
              width={thumbWidth}
              height={thumbHeight}
              alt={thumbAlt}
            />
          </figure>
          {/* Play icon */}
          <span className="pointer-events-none absolute p-2.5 hover:scale-110">
            <span className="relative flex items-center gap-3 text-center m-auto hover:scale-110">
              <img src={play} className="w-24 hover:scale-120" alt="Play icon" />
            </span>
          </span>
        </button>
      ) : (
        <div className="w-full mx-auto rounded-lg mx-auto text-center ">
          <iframe className="m-auto rounded-[1rem] border-2 border-gray-500 mmd:w-[60%] w-[90%] mmd:h-[30rem] h-[20rem]" src="https://www.youtube.com/embed/jMibJGHemEA?si=9aID1gRrbPVYfxuV" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        </div>
      )}
    </div>
  );
}

// Ejemplo de uso

