import React from "react";
import { useNavigate } from 'react-router-dom';
import forma2 from '../assets/images/formas/pintura1.svg';
import ficha from '../assets/images/desprogramados/ficha3.svg';
import recursos from '../assets/images/desprogramados/recursos.json';

export function DesprogramadosRecursos() {
    const navigate = useNavigate();
    const recursosArray = Object.values(recursos);

    return (
        <>
            <section id="game" className="flex bg-orange-50 w-full mx-auto">
                <div className="mt-20 w-full mb-20">
                    <p className="text-center text-3xl text-gray-800 w-full mmd:w-[85%] mx-auto md:2-[80%] md:text-3xl">
                    Recursos didácticos
                    </p>
                    <h2 className="text-center title2 text-gray-800 font-semibold w-full mmd:w-[85%] mx-auto md:2-[80%] md:text-3xl">
                        ¿Cómo
                        <span
                            className="bg-no-repeat bg-contain inline-block md:mt-2"
                            style={{
                                backgroundImage: `url(${forma2})`,
                                backgroundSize: '100%',
                                backgroundPosition: 'center',
                                padding: '.8rem 1rem'
                            }}
                        >
                            <b>implementar</b>
                        </span> desprogramados en el aula?
                    </h2>

                    

                    <div className="mt-10 m-auto w-full mmd:w-[85%] mx-auto md:2-[80%] flex column lg:flex-wrap lg:flex-row  gap-6">
                        <div className="mt-10 w-full mmd:w-[85%] mx-auto mmd:w-[90%] grid grid-cols-1 mmd:grid-cols-3 gap-6">
                        {recursosArray.map((item, index) => (
                            <div
                                className="bg-white shadow-sm rounded-3xl p-4 cursor-pointer transform transition duration-300 hover:translate-y-[-10px] hover:shadow-lg"
                                key={index + 1}
                                onClick={() => navigate(`/desprogramados/blog/${index + 1}`)}
                            >
                                <img
                                    src={ficha}
                                    className="w-[20%] h-auto mx-auto ml-0 mb-4"
                                    alt=""
                                />
                                <h3 className="text-2xl font-semibold text-left w-[90%] ">
                                    {item.title}
                                </h3>
                                <p className="text-lg text-justify mx-auto">
                                    {item.description}
                                </p>
                            </div>
                        ))}
                    </div>

                    </div>
                </div>
            </section>
        </>
    );
}
