import React from "react";

import forma1 from '../assets/images/formas/forma1.png';
import forma2 from '../assets/images/formas/pintura1.svg';
import imagen from '../assets/images/desprogramados/ficha.png';

import { FaArrowDown } from "react-icons/fa6";

export function Banner() {
    return (
        <>  
            <section id="banner" className="w-screen min-h-screen flex bg-orange-50">
            <div className="mt-20 w-full min-h-screens mmd:w-full mmd:p-10 relative overflow-hidden">
                    
                    <div className="flex flex-col mmd:flex-row justify-center text-center mx-auto items-center mb-20">
                        
                        <div className="w-full mmd:w-2/4 text-left mt-20 mx-auto ml-40 2xl:ml-20 md:mx-auto md:mt-80 md:pt-4">

                    <h2 className="z-20 relative w-full text-justify mx-auto 
                    md:w-[90%] text-7xl 4xl:text-8xl md:text-[2.5rem]">
                        el futuro es hoy<br/>¿lo
                        <span 
                            className="bg-no-repeat bg-contain inline-block md:mt-2 animate-fade-up animate-duration-1000 animate-ease-linear"
                            style={{
                                backgroundImage: `url(${forma2})`,
                                backgroundSize: '100%',
                                backgroundPosition: 'center',
                                padding: '.8rem 3rem'
                            }}
                        >
                        <b>jugamos</b>?
                        </span>
                    </h2>
                   
                            
                    <p className="z-20 relative text-2xl mt-4 text-justify inline-block 
                    md:text-xl md:px-2 w-[70%] md:mx-auto md:justify-center">
                        Ayudamos a que el aprendizaje sea más efectivo diseñando experiencias educativas con nuestra metodología STEAM y gamificada
                    </p>
                    
                    <div className="md:flex md:mx-auto md:align-center">
                    <a className="mmd:mx-6 mmd:text-2xl text-xl mmd:ml-[0rem] mt-12 text-center border-gray-700 border-2 rounded-full py-2 px-12 md:px-4 inline-block text-gray-700 md:mt-10 hover:bg-amber-400 md:mx-auto md:z-10 md:relative md:inline-block" href="#metodo">
                        CONOCENOS
                    </a>
                    <a className="mmd:mx-6  mmd:text-2xl text-xl mmd:ml-[0rem] mt-12 text-center bg-gray-700 border-2 border-gray-700 rounded-full py-2 px-12 md:px-4 inline-block text-white md:mt-10 hover:bg-amber-400 md:mx-auto md:z-10 md:relative md:inline-block" href="#soluciones">
                        SOLUCIONES
                    </a>
                   
                    </div>

                    </div>
                    
                    <img src={imagen} alt="experiencias" className="4xl:w-[50%] w-[45%] z-20 absolute right-[1rem]
                        2xl:mt-8 md:top-[-1rem] md:w-[85%] md:right-0 md:left-0 md:mx-auto" />

                    <img src={forma1} alt="forma" className="w-[85%]  absolute bottom-[-30rem] right-[-50rem] origin-left opacity-[.3] md:right-[-10rem] md:bottom-[-8rem] md:w-[500%] 
                    4xl:w-[75%]" />
                </div>
                
               
                
               
            </div>
            </section>
        </>
    );
}
