import React from "react";

import { DesprogramadosBanner } from '../components/desprogramadosBanner';
import  { DesprogramadosGame }  from '../components/desprogramadosGame';
import { DesprogramadosSolutions }  from '../components/desprogramadosSolutions';
import { DesprogramadosRecursos } from "../components/desprogramadosRecursos";
import { DesprogramadosForm } from '../components/desprogramadosForm';
import { Contacto } from '../components/desprogramadosContacto';
import ModalVideo from '../components/video'
function Desprogramados() {

    return (
        <>
            <DesprogramadosBanner />

            <ModalVideo
            videoId="jMibJGHemEA"
            thumb="https://img.youtube.com/vi/jMibJGHemEA/maxresdefault.jpg"
            thumbWidth="720"
            thumbHeight="360"
            thumbAlt="Video"
            />
            <DesprogramadosGame />
            <DesprogramadosSolutions />
            <DesprogramadosRecursos />
            <DesprogramadosForm />
            <Contacto />
        </>
    )
}

export default Desprogramados;