import React from 'react';
import { useParams } from 'react-router-dom';
import ficha from '../assets/images/desprogramados/ficha3.svg';
import recursos from '../assets/images/desprogramados/recursos.json';
import logo from '../assets/images/desprogramados/logo.png';

function DynamicPage() {
    const { id } = useParams();
    const rec = recursos[id];

    if (!rec) {
        return (
            <section className="w-screen min-h-screen flex justify-center items-center bg-orange-50">
                <h2 className="text-xl text-gray-700">Recurso no encontrado</h2>
            </section>
        );
    }

    const { title, description, instruction } = rec;
    const { steps, materials, recommended_age, disciplines, curricular_application, objective, specific_instruction } = instruction || {};

    return (
        <section id="banner" className="w-screen min-h-screen bg-orange-50 mx-auto justify-center items-center">
            <img src={logo} className="w-[50%] mt-20 pt-12 mx-auto mb-4" alt="Ficha" />
            <br />
            <div className="mt-2 w-[98%] justify-center mmd:w-[70%] align-center min-h-screens mx-auto text-center overflow-hidden relative left-0 right-0 bg-white rounded-md border-[.1rem] border-gray-400 shadow-lg pb-24">
                <img src={ficha} className="w-[20%] mt-8 mx-auto mb-4" alt="Ficha" />
                <h2 className="title z-20 relative w-[90%] text-center mx-auto 
                md:w-[90%] md:text-4xl">{title}</h2>

                <div className="mt-10 w-[30%] mx-auto h-[1.2px] bg-gray-400"></div>

                <p className="z-20 relative mx-[1rem] text-2xl mt-12 text-justify px-10 inline-block text-black md:mt-2 md:text-lg md:mx-2 md:px-2 mmd:w-[95%] mx-auto">
                    {description}
                </p>

                <div className="mt-10 px-10 text-left mmd:w-[95%] mx-auto">
                    <h3 className="text-2xl font-semibold mb-4">Instrucciones:</h3>
                    <p className="mb-2 text-lg"><strong>Materiales:</strong> {materials}</p>
                    <p className="mb-2 text-lg"><strong>Edad Recomendada:</strong> {recommended_age}</p>
                    <p className="mb-2 text-lg"><strong>Disciplinas:</strong> {disciplines}</p>
                    <p className="mb-2 text-lg"><strong>Aplicación Curricular:</strong> {curricular_application}</p>
                    <p className="mb-2 text-lg"><strong>Objetivo:</strong> {objective}</p>
                    <p className="mb-2 text-lg"><strong>Instrucción Específica:</strong> {specific_instruction}</p>

                    {steps && (
                        <>
                            <h3 className="text-2xl font-semibold mt-6 mb-4">Paso a Paso:</h3>
                            <ol className="list-decimal pl-5">
                                {steps.map((step, index) => (
                                    <li key={index} className="mb-2 text-black text-lg">{step}</li>
                                ))}
                            </ol>
                        </>
                    )}
                </div>
            </div>
            <br /><br /><br />
        </section>
    );
}

export default DynamicPage;
